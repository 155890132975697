.one-header-search {
	background-color: var(--one-header-color-white);
	display: block;
	height: 71px;
	left: 0px;
	opacity: 0;
	position: absolute;
	right: 0px;
	top: 0px;
	transition: opacity 240ms cubic-bezier(0.75, 0.02, 0.5, 1) 0s;
	visibility: hidden;
	width: 100%;
	z-index: 5;
}

.one-header-search-featureapp {
	background-color: var(--one-header-color-white);
	margin: 0px;
	opacity: 0;
	position: relative;
	transition: opacity 120ms ease 0s;
	width: 100%;
}

.one-header-search .search-header {
	background: rgb(255, 255, 255) none repeat scroll 0% 0%;
	max-height: 72px;
	display: flex;
	flex: 1 1 auto;
	-moz-box-flex: 1;
	flex-direction: row;
}
.one-header-search .search-header-content {
	margin: 17px 4%;
	max-width: 1440px;
	align-self: flex-start;
	flex: 1 1 auto;
	-moz-box-flex: 1;
}
.one-header-search .search-field-container {
	margin-left: 42px;
	box-sizing: border-box;
	position: relative;
	display: flex;
	flex: 1 1 auto;
	-moz-box-flex: 1;
	flex-direction: column;
}
.one-header-search .search-field {
	white-space: nowrap;
	display: grid;
	grid-template-columns: [search-icon] 24px [input] 1fr [clear-icon] 24px [end];
	-moz-box-align: center;
	align-items: center;
}
.one-header-search .search-field::after {
	grid-column: search-icon / end;
	display: block;
	box-sizing: border-box;
	width: 100%;
	height: 2px;
	margin-top: -1px;
	border-bottom: 1px solid rgb(128, 128, 128);
	transition-timing-function: cubic-bezier(0.75, 0.02, 0.5, 1);
	transition-duration: 0.25s;
	transition-property: border-bottom-color, background-color;
	content: "";
}
.one-header-search--focus .search-field::after {
	background-color: rgb(0,0,0);
	border-bottom-color: rgb(0,0,0);
}
.one-header-search .search-field .search-icon {
	grid-area: 1 / search-icon;
	display: inline-block;
	height: 24px;
	line-height: 24px;
}
.one-header-search .search-field label {
	text-overflow: ellipsis;
	overflow: hidden;
	opacity: 1;
	display: block;
	grid-area: 1 / search-icon / auto / end;
	margin: 0px;
	padding: 4px 30px 8px;
	font-size: 16px;
	font-stretch: 105%;
	line-height: 24px;
	color: rgb(102, 102, 102);
	transition-timing-function: cubic-bezier(0.75, 0.02, 0.5, 1);
	transition-duration: 0.25s;
	transition-property: color, opacity;
	pointer-events: none;
}
.one-header-search--has-value .search-field label {
	opacity: 0;
}
.one-header-search .search-field input {
	color: rgb(51, 51, 51);
	display: block;
	grid-area: 1 / search-icon / auto / end;
	margin: 0px;
	padding: 4px 30px 8px;
	font-size: 16px;
	font-stretch: 105%;
	line-height: 24px;
	background-color: transparent;
	border: 0px none;
	transition: color 0.25s cubic-bezier(0.75, 0.02, 0.5, 1) 0s;
	appearance: none;
}

@media all and (min-width: 768px) {
	.one-header-search .search-field-container {
		margin: 0px auto;
		width: 40vw;
		max-width: 576px;
	}
}

@media all and (min-width: 1024px) {
	.one-header-search .search-header-content {
		margin: 17px auto;
	}
}

.one-header-search .header-search-controls {
	position: absolute;
	top: 0px;
	width: 100%;
}
.one-header-search .header-search-controls > legend {
	display: block;
	clip: rect(0px, 0px, 0px, 0px);
	clip-path: inset(100%);
	height: 1px;
	overflow: hidden;
	position: absolute;
	width: 1px;
}
.one-header-search .header-search-controls > button {
	background-color: transparent;
	border: medium none;
	color: var(--one-header-color-black);
	cursor: pointer;
	display: flex;
	left: var(--one-header-side-spacing);
	opacity: 0;
	padding: 0px;
	position: absolute;
	top: var(--one-header-space-l);
	transition: opacity 120ms ease 0s;
}
.one-header--search-open .header-search-controls > button {
	opacity: 1;
}
.one-header-search .header-search-controls > button > svg {
	height: 24px;
	width: 24px;
	transform: scaleX(-1);
}

.one-header-search .results-loader {
	text-align: center;
}

.one-header--search-open .one-header-search {
	visibility: visible;
	opacity: 1;
}
.one-header--search-open .one-header-search-featureapp {
	opacity: 1;
}

.one-header--search-closing .one-header-search {
	opacity: 0;
	transition: visibility 0s linear 0s, opacity 60ms;
	transition-delay: 180ms;
	visibility: visible;
}
