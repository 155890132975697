.one-footer{
    background:var(--one-header-color-black);
    color:var(--one-header-color-white);
    padding:var(--one-header-space-l) var(--one-footer-side-spacing) 36px;
    font-size: 10px;
}
.one-footer__inner-wrapper{
    margin:0 auto;max-width:1920px;
}
.one-footer__toolbar{
    display:flex;
    flex-wrap:wrap;
    justify-content:flex-end;
}
.one-footer__toolbar--border-top{
    border-top:1px solid var(--one-header-color-grey-70);
    justify-content:space-between;
    margin:var(--one-header-space-s) 0 0;
    padding: 24px 0 0;
}
.one-footer__copyright{
    margin:0 52px 16px 0;
    font-size: 1.3em;
    line-height: 1.8em;
    font-family: AudiTypeScreen,sans-serif;
    font-weight: bold;
}
.one-footer__copyright,.one-footer__disclaimer{
    color:var(--one-header-color-grey-15);
}
.one-footer__disclaimer a{
    color:var(--one-header-color-white);
    text-decoration:none;
}
.one-footer__disclaimer a:hover{
    color:var(--one-header-color-grey-15);
}
@media (min-width:768px){
    .one-footer__toolbar--border-top{
        margin:0;
    }
}
@media (min-width:1024px){
    .one-footer{
        padding:var(--one-header-space-l) var(--one-footer-side-spacing) 40px;
    }
}
@media (min-width:1440px){
    .one-footer{
        padding:var(--one-header-space-l) var(--one-footer-side-spacing) 52px;
    }
}
