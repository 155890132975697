
/************************************
* Audi UI
************************************/

// $aui-breakpoint: ( tiny : 480px, xsmall : 600px, small : 740px, medium : 960px, large : 1264px, xlarge : 1424px, huge : 1584px, ) !default;
$aui-breakpoint: (
  tiny: 480px,
  xsmall: 600px,
  small: 740px,
  medium: 960px,
  large: 1264px,
  xlarge: 1424px
);
// $aui-modular-scale: ( default: ( base: 16px, ratio: 1.14, ), small: ( ratio: 1.17, ), large: ( base: 18px, ratio: 1.21, ), huge: ( base: 20px, ratio: 1.25, ), ) !default;
$aui-modular-scale: (
  default: ( base: 16px, ratio: 1.14, ),
  small: ( ratio: 1.17, ),
  large: ( base: 14px, ratio: 1.21, )
);

$font-path: 'https://cdn01.audi.com.au/fonts';

@import '../audi-ui-components/styles/main';

@import 'aui-overrides';

// for brand site header
@font-face {
    font-family: "AudiTypeNormal";
    src: url("#{$font-path}/Audi_Type/Audi-Type-Normal.eot");
    src: url("#{$font-path}/Audi_Type/Audi-Type-Normal.eot?#iefix") format("embedded-opentype"),
         url("#{$font-path}/Audi_Type/Audi-Type-Normal.woff") format("woff"),
         url("#{$font-path}/Audi_Type/Audi-Type-Normal.ttf") format("truetype"),
         url("#{$font-path}/Audi_Type/Audi-Type-Normal.svg#AudiType-Normal") format("svg");
    font-weight: normal;
    font-style: normal;
}

@import 'rangeslider';

/************************************
* Bootstrap
************************************/

// use breakpoints from audi UI
$grid-breakpoints: map-merge((zero: 0,), $aui-breakpoint);

@import 'bootstrap';


/************************************
* base layout
************************************/
html {
  scroll-behavior: smooth;
}
// html, body, #AppContainer, .App {
//   height: 100%;
// }

#main, .audi-brand-header {
  max-width: 1424px;
  margin: 0 auto;
  // width: 92%;
  z-index: 1000;
}
.main-inner {
  width: 92%;
  max-width: 1177.6px;
  margin: 0 auto;
}

.order-wrap {
  position: relative;
  .content-wrap {
    margin: 0 auto;
    max-width: map-get($aui-breakpoint, 'xlarge');
    @media (min-width: map-get($aui-breakpoint, 'large')) {
      width: 100%;
    }
  }
}

.form-tabs {
  position: relative;
  font-size: 10px;
  padding: 0 30px;
  @media (min-width: map-get($aui-breakpoint, 'xsmall')) {
    font-size: inherit;
  }
  @media (min-width: map-get($aui-breakpoint, 'medium')) {
    padding: 0 40px;
  }
  @media (min-width: map-get($aui-breakpoint, 'large')) {
    padding: 0 53px;
  }
  a {
    padding: .8rem 1.25rem;
    display: inline-block;
    font-family: $aui-font-extended;
    line-height: normal;
    outline: none;
    color: rgba(0,0,0,.5);
    &.active {
      background: $aui-color-white;
      box-shadow: 0 -2px 4px 2px rgba(0, 0, 0, 0.1);
      font-weight: $aui-font-weight-bold;
      color: $aui-color-black;
    }
  }
}

.vehicle-hero {
  display: block;
  height: 50vw;
  max-height: 40vh;
  text-align: center;
  .vehicle-hero__img {
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;
  }
  .loader.placeholder {
    height: 100%;
    padding: 0;
  }
}


/************************************
* text
************************************/

.currency sup { font-size: 55%; }

.text-link {
  color: $aui-color-black;
  text-decoration: none;
  border: none;
  border-bottom: 1px solid;
  cursor: pointer;
  transition: color 0.3s $aui-easing;
  &:hover {
    color: $aui-color-gray30;
  }
}
button.text-link {
  background: none;
  padding: 0;
  box-shadow: none;
  outline: none;
  text-align: left;
}

span.prepend-dollar {
  position: absolute;
  bottom: 7px;
}

.disclaimer {
  font-size: .7rem;
  line-height: 1.5;
}


/************************************
* images
************************************/
.responsive-aspect-ratio {
  position: relative;
  height: 0;
  padding-top: 54.8%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  &.wide {
    padding-top: 38.5%;
  }
  &.square {
    padding-top: 100%;
  }
  &.interior {
    background-size: 90% auto;
    background-position: center 22%;
  }
  & > * {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}


/************************************
 * carousel default styles
 ************************************/
.slider {
  .slider-control-bottomcenter {
    display: none;
  }
  .slider-control-centerleft,
  .slider-control-centerright {
    button {
      background: transparent;
      border: none;
      outline: none;
      padding: 0;
      cursor: pointer;
    }
  }
  .slider-control-centerleft {
    left: -12px !important;
    button:disabled,
    button[disabled] {
      display: none;
    }
  }
  .slider-control-centerright {
    right: -12px !important;
    button:disabled,
    button[disabled] {
      display: none;
    }
  }
}


/************************************
* spinner
************************************/
@import "spinner";


/************************************
* collapsible section
************************************/
@import "collapsible";


/************************************
* sidebar
************************************/
@import "sidebar";


/************************************
* steps list
************************************/
@import "steps-list";


/************************************
* sections
************************************/
@import "section";
@import "hero-section";


/************************************
* equipment
************************************/
.equipment {
  .audiicon {
    color: $aui-color-white;
    top: 95%;
    left: 95%;
  }
  .equipment-thumb {
    position: relative;
    height: 0;
    padding-top: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .equipment-name {
    // font-family: $aui-font-extended;
  }
  .equipment-desc {

  }
}


/************************************
* Enquiry page
************************************/
@import "enquiry";

/************************************
* Reservation page
************************************/
@import "reservation";

/************************************
* Trade-in form
************************************/
.trade-in-form {
  .estimate {
    .estimate-arrow {
      width: 100px;
      height: 1px;
      border-top: 1px solid;
      position: relative;
      display: inline-block;
      margin: 0 10px;
      vertical-align: middle;
      &::before, &::after {
        content: " ";
        width: 9px;
        height: 9px;
        border-style: solid;
        border-width: 1px 1px 0 0;
        display: block;
        position: absolute;
        top: -5px;
      }
      &::before {
        left: 1px;
        transform: rotate(225deg);
      }
      &::after {
        right: 1px;
        transform: rotate(45deg);
      }
    }
  }
  .rb-list__item {
    padding-top: 18px;
    padding-bottom: 20px;
    border-bottom: 1px solid $aui-color-gray80;
    cursor: pointer;
    .audiicon {
      visibility: hidden;
    }
    &:hover {
      background: $aui-color-gray95;
      .audiicon {
        visibility: visible;
      }
    }
  }
}

/************************************
* vehicle listing
************************************/
.sc-list {
  .aui-select, .aui-textfield {
    padding: 6px 0;
    margin-right: 10px;
    &::after {
      top: 2rem;
    }
  }
  .select-state {
    width: calc(100% - 50px);
    display: inline-block;
  }
  .select-model {
    min-width: 240px;
  }
  .location-field {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    background: $aui-color-gray40;
    .place-field {
      flex: 1 1 auto;
      position: relative;
      .autocomplete-dropdown-container {
        position: absolute;
        left: 0;
        width: 100%;
        background: $aui-color-gray30;
        color: $aui-color-light;
        z-index: 100;
        &.has-items {
          box-shadow: 0 0 2px 0 rgba(0,0,0,.1),0 0 40px 0 rgba(0,0,0,.15);
        }
      }
      .suggestion-item {
        padding: 10px;
        border-top: 1px solid rgba($aui-color-light, 0.5);
        &:first-child {
          border-top: none;
        }
        &.active {
          background: $aui-color-gray40;
        }
      }
    }
    .aui-select {
      width: 100px;
      flex: 0 1 auto;
    }
    .aui-button {
      background-color: $aui-color-red;
      border-color: $aui-color-red;
      color: $aui-color-white;
      opacity: 1;
    }
    @media (min-width: map-get($aui-breakpoint, 'medium')) {
      justify-content: flex-end;
      background: none;
      .place-field {
        width: 300px;
        flex: 0 1 auto;
      }
      .aui-select {
        width: 85px;
      }
    }
  }
  .intro {
    sup {
      cursor: pointer;
    }
    .aui-button {
      font-weight: $aui-font-weight-bold;
      .aui-button__text::after {
        background-image: url('data:image/svg+xml,#{aui-svg-arrow($aui-color-red)}');
      }
    }
  }
}
.sc-car-tile {
  border-bottom: 1px solid $aui-color-gray80;
  .sc-car-tile__image {
    width: 100%;
    padding-top: 62%;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: center;
    background-color: $aui-color-gray95;
    cursor: pointer;
  }
  .audiicon {
    vertical-align: text-bottom;
  }
  .title {
    font-weight: $aui-font-weight-bold;
    font-size: 1.3rem;
    line-height: 2.5rem;
  }
  .description {
    color: $aui-color-gray60;
    font-weight: bolder;
    margin-bottom: 12px;
  }
  .price {
    margin: 0 0 24px;
    font-weight: $aui-font-weight-bold;
    sup {
      cursor: pointer;
    }
  }
}
