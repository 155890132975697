/************************************
 * base layout
 ************************************/
 .sidebar-wrap {
  position: sticky;
  bottom: 0;
  width: 100%;
  height: auto;
  display: block;
  z-index: 200;

  @media (max-width: map-get($aui-breakpoint, 'xsmall') - 1) {
    .audiicon-small { width: 18px; height: 18px; }
    .audiicon-large { width: 28px; height: 28px; }
  }

  @media (max-width: map-get($aui-breakpoint, 'large') - 1) {
    border-top: 1px solid $aui-color-gray80;
    background: $aui-color-white;
  }
   // desktop
  @media (min-width: map-get($aui-breakpoint, 'large')) {
    position: absolute;
    z-index: 200;
    top: 0;
    right: 0;
    bottom: auto;
    width: 24%;
    height: 100%;
    .sidebar {
      position: sticky;
      top: 70px;
      padding: 36px 40px 100px 0;
    }
  }
}

/************************************
 * sidebar boxes
 ************************************/
.sidebar-box {
  sup {
    font-family: $aui-font-extended;
    font-size: 55%;
    padding-left: 3px;
    vertical-align: super;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      padding: 5px 0 5px 30px;
      position: relative;
      .audiicon {
        position: absolute;
        left: 0;
        top: 5px;
      }
    }
  }
  .faq-link {
    @extend .aui-headline-4;
    &.d-large-none {
      padding: 0;
    }
  }
  @media (max-width: map-get($aui-breakpoint, 'large') - 1) {
    font-size: 90%;
    line-height: 1.5;
  }
  @media (max-width: map-get($aui-breakpoint, 'xsmall') - 1) {
    font-size: 2.5vw;
    .aui-headline-4 {
      font-size: 15px;
    }
  }
  @media (min-width: map-get($aui-breakpoint, 'large')) {
    margin: 0 0 40px;
    border: 1px solid $aui-color-gray50;
    background: $aui-color-white;
    padding: 24px 19px 28px;

  }
}
