.one-header-searchflyout {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	width: 100vw;
	max-width: 1920px;
	z-index: 20;
}
.one-header-flyout-results {
	background-color: rgb(255, 255, 255);
	min-height: 240px;
	height: calc(-72px + 100vh);
}
.one-header-suggested-search {
	margin-left: 4%;
	margin-right: 4%;
	margin-block-start: 32px;
	margin-block-end: 52px;
}
.one-header-suggested-search .search-suggestions-label {
	color: rgb(0, 0, 0);
	font-size: 12px;
	line-height: 20px;
	margin-block-end: 16px;
}
.one-header-suggested-search .suggested-terms-list {
	display: flex;
	flex: 1 1 auto;
	-moz-box-flex: 1;
	flex-direction: column;
}
.one-header-suggested-search .suggested-term {
	position: relative;
	display: inline-flex;
	align-items: center;
	margin: 0 0 12px;
	padding: 0px;
	color: #000;
	text-align: center;
	text-decoration: none;
	vertical-align: middle;
	background-color: transparent;
	border: none;
	cursor: pointer;
	transition-timing-function: cubic-bezier(0.75, 0.02, 0.5, 1);
	transition-duration: 250ms;
	transition-property: color, background-color, border-color;
	appearance: none;
	user-select: none;
	text-align: start;
	font-size: 16px;
	font-family: AudiTypeNormal, Verdana, sans-serif;
	font-stretch: 105%;
	line-height: 24px;
	padding-inline-end: 5px;
}
.one-header-suggested-search .suggested-term:hover {
	color: rgb(76, 76, 76);
}
.one-header-suggested-search .suggested-term svg {
	stroke: rgb(0, 0, 0);
	flex-shrink: 0;
	width: 0.5em;
	height: 0.8125em;
	margin-inline-start: 0.25em;
	transition-duration: 250ms;
	transition-timing-function: cubic-bezier(0.75, 0.02, 0.5, 1);
	transition-delay: 0s;
	transition-property: transform, stroke;
}
.one-header-suggested-search .suggested-term:hover svg {
	transform: translateX(5px);
	stroke: rgb(76, 76, 76);
}
@media all and (min-width: 1024px) {
	.one-header-suggested-search {
		margin-left: auto;
		margin-right: auto;
		width: calc(48px + 40vw);
		max-width: calc(624px);
	}
	.one-header-suggested-search .search-suggestions-label {
		padding: 0px 24px;
	}
	.one-header-suggested-search .suggested-term {
		padding: 8px 24px;
		margin-bottom: 0px;
	}
	.one-header-suggested-search .suggested-term:hover {
		background-color: rgb(242, 242, 242);
	}
	.one-header-suggested-search .suggested-term svg {
		display: none;
	}
}
