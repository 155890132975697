.one-header-nav {
	background-color: var(--one-header-color-white);
	display: block;
	height: 0px;
	left: 0px;
	opacity: 1;
	overflow: hidden;
	position: absolute;
	top: var(--one-header-height);
	transition: height 420ms cubic-bezier(0.75, 0.02, 0.5, 1) 0s;
	width: 100%;
	z-index: 1;
}
.one-header-nav__list {
	height: 100%;
	opacity: 0;
	padding: calc(var(--one-header-space-xxl) - var(--one-header-space-l) / 2) var(--one-header-side-spacing);
	transform: scaleY(0);
	transform-origin: center top 0px;
	transition: opacity 240ms cubic-bezier(0.75, 0.02, 0.5, 1) 0s, transform 420ms cubic-bezier(0.75, 0.02, 0.5, 1) 0s;
}

@media all and (max-width: 1439px) {
	.one-header--nav-open .one-header-nav {
		background-color: var(--one-header-color-white);
		display: block;
		height: calc(728px - var(--one-header-height));
		left: 0px;
		opacity: 1;
		overflow: hidden auto;
		position: absolute;
		top: var(--one-header-height);
		transition: height 420ms cubic-bezier(0.75, 0.02, 0.5, 1) 0s;
		width: 100%;
		z-index: 1;
	}
	.one-header--nav-open .one-header-nav__list {
		opacity: 1;
		transform: scaleY(1);
	}
}

@media all and (min-width: 1440px) {
	.one-header-nav {
		height: var(--one-header-height);
		overflow: initial;
		position: static;
		top: 0px;
		z-index: 1;
	}
	.one-header-nav__list {
		display: flex;
		flex-wrap: nowrap;
		height: auto;
		margin-right: var(--one-header-space-xl);
		opacity: 1;
		padding: 0px;
		position: static;
		top: 0px;
		transform: none;
		transition: none 0s ease 0s;
		z-index: 1;
	}
}
