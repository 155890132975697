/**
 * line-heights are calculated.
 * example: font-size: 21px / line-height: 30px
 * font-size is 2.1em since based on 10px base-font-size
 * line-height is calc(3 / 2.1) where 3 stands for 30px and 2.1 is the font-size without unit
 */

.one-header {
	font-size: 10px;
	touch-action: manipulation;
}

.one-header__headline-order-3 {
	color: #000;
	font-family: AudiTypeBold, sans-serif;
	font-size: 2.1em;
	font-weight: normal;
	line-height: calc(3 / 2.1);
}

.one-header__headline-order-3--normal {
	font-family: AudiTypeNormal, sans-serif;
}

.one-header__copy-s,
.one-header__copy-m {
	color: #000;
	font-family: AudiTypeNormal, Verdana, sans-serif;
	font-weight: normal;
}

.one-header__copy-s {
	font-size: 1.3em;
	line-height: calc(1.8 / 1.3);
}

.one-header__copy-m {
	font-size: 1.6em;
	line-height: calc(2.4 / 1.6);
}

.one-header__copy-s--bold,
.one-header__copy-m--bold {
	font-family: AudiTypeBold, sans-serif;
}

.one-header__copy-s--link,
.one-header__copy-m--link {
	text-decoration: none;
}

.one-header__copy-s--link:hover,
.one-header__copy-m--link:hover {
	color: var(--one-header-color-grey-70);
}
