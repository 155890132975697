@media all and (min-width: 1440px) {
	.one-header-active-bar {
		background-color: var(--one-header-color-black);
		display: block;
		height: 2px;
		left: 0px;
		position: absolute;
		top: calc(2 * var(--one-header-space-l));
		transition: left 240ms cubic-bezier(0.75, 0.02, 0.5, 1) 0s, width 240ms cubic-bezier(0.75, 0.02, 0.5, 1) 0s, opacity 240ms ease 0s;
		width: 0px;
	}
}
