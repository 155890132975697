.one-header-teaser {
	margin-bottom: var(--one-header-space-xxxl);
	overflow: hidden;
	width: 100%;
}

.one-header-teaser__inner {
	overflow: hidden;
	position: relative;
}

.one-header-teaser__image-wrapper {
	opacity: 1;
	transition: opacity 300ms cubic-bezier(0.75, 0.02, 0.5, 1) 120ms;
	z-index: 1;
}
.one-header-teaser__image {
	display: block;
	height: auto;
	position: relative;
	width: 100%;
	opacity: 1;
	padding-bottom: 0px;
}

.one-header-teaser__link {
	align-items: flex-end;
	bottom: 0px;
	display: flex;
	-moz-box-pack: justify;
	justify-content: space-between;
	position: absolute;
	text-decoration: none;
	transform: translateY(0px);
	transition: transform 660ms cubic-bezier(0.75, 0.02, 0.5, 1) 660ms;
	width: 100%;
}
.one-header-teaser__text {
	display: contents;
}
.one-header-teaser__text span {
	-moz-box-flex: 1;
	flex-grow: 1;
	margin: 0px;
	margin-inline-end: 16px;
	margin-block-end: 16px;
	margin-inline-start: 16px;
	color: rgb(242, 242, 242);
	font-weight: 400;
	font-style: normal;
	letter-spacing: -0.015em;
	text-decoration: none;
	font-size: 16px;
	font-family: 'AudiTypeExtended', Verdana, Geneva, sans-serif;
	line-height: 24px;
}
.one-header-teaser__link svg {
	color: var(--one-header-color-white);
	flex-shrink: 0;
	height: 48px;
	width: 48px;
}

@media all and (min-width: 768px) {
	.one-header-teaser {
		max-width: 432px;
		width: 50%;
	}
}

@media all and (min-width: 1440px) {
	.one-header-teaser {
		width: 30vw;
	}
	.one-header-teaser__text span {
		font-size: 18px;
		line-height: 28px;
	}
}
