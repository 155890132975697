.audi-link-s {
	/* color: var(--one-header-color-black); */
	cursor: pointer;
	display: inline-block;
	font-family: AudiTypeScreen, sans-serif;
	font-weight: 500;
	font-size: 1.3em;
	line-height: 20px;
	text-decoration: none;
}

.audi-link-s:hover,
.audi-link-s:focus {
	color: var(--one-header-color-grey-15);
}

.audi-link-s[data-state="inactive"] {
	color: var(--one-header-color-grey-30);
	pointer-events: none;
}

.audi-link-s--underline {
	text-decoration: underline;
}

.audi-link-s--arrow {
	font-size: 0;
}

.audi-link-s--arrow .audi-link-s__text {
	display: inline;
	font-size: 13px;
	vertical-align: middle;
}

.audi-link-s--arrow-front {
	align-items: flex-start;
	display: inline-flex;
	flex-direction: row-reverse;
	justify-content: flex-end;
}

.audi-link-s--arrow .audi-link-s__text,
.audi-link-s--arrow-front .audi-link-s__text {
	padding: var(--one-header-space-xxs) 0;
}

.audi-link-s__icon {
	flex: none; /* arrow front */
	height: var(--one-header-icon-size-small);
	vertical-align: middle; /* arrow */
	width: var(--one-header-icon-size-small);
}

.audi-link-s--arrow .audi-link-s__text,
.audi-link-s--arrow-front .audi-link-s__icon {
	margin-right: var(--one-header-space-s);
}

.audi-link-s--inverted {
	color: var(--one-header-color-white);
}

.audi-link-s--inverted:hover,
.audi-link-s--inverted:focus {
	color: var(--one-header-color-grey-15);
}

.audi-link-s--inverted[data-state="inactive"] {
	color: var(--one-header-color-grey-70);
}

.audi-link-s--arrow.audi-link-s--no-inline-image {
	font-size: 1.3em;
	line-height: 1.4em;
	padding: 0 calc(var(--one-header-space-xs) + var(--one-header-icon-size-small)) 0 0;
}

.audi-link-s--no-inline-image::after {
	/* background-image: url("../assets/icons/svg/forward-small.svg"); */
	content: "";
	display: inline-block;
	height: var(--one-header-icon-size-small);
	position: absolute;
	transform: translateX(var(--one-header-space-xs)) translateY(-3px);
	vertical-align: middle;
	width: var(--one-header-icon-size-small);
}

.audi-link-s--inverted.audi-link-s--no-inline-image::after {
	filter: invert(1);
}

.audi-link-s--inverted.audi-link-s--no-inline-image:hover::after,
.audi-link-s--inverted.audi-link-s--no-inline-image:focus::after {
	filter: invert(.7);
}

.audi-link-s--inverted.audi-link-s--no-inline-image[data-state="inactive"]::after {
	filter: invert(.3);
}
