.one-header-sub-nav {
	backface-visibility: hidden;
	background-color: var(--one-header-color-white);
	inset: 0px;
	top: 0px;
	bottom: 0px;
	display: block;
	height: 0px;
	overflow-y: hidden;
	padding: 0 var(--one-header-side-spacing);
	position: absolute;
	transform: translateX(100%) scaleY(1);
	transition: transform 420ms cubic-bezier(0.75, 0.02, 0.5, 1) 0s, height 420ms cubic-bezier(0.75, 0.02, 0.5, 1) 0s;
}
.one-header-nav-item--subnav-open .one-header-sub-nav {
	height: calc(462px - var(--one-header-height));
	overflow-y: auto;
	transform: translateX(0px);
}

.one-header-sub-nav__button {
	-moz-box-align: center;
	align-items: center;
	background-color: var(--one-header-color-white);
	border-color: currentcolor currentcolor rgb(204, 204, 204);
	border-style: none none solid;
	border-width: medium medium 1px;
	border-image: none 100% / 1 / 0 stretch;
	color: var(--one-header-color-black);
	cursor: pointer;
	display: flex;
	flex-flow: row nowrap;
	padding: var(--one-header-space-xl) 0 var(--one-header-space-m) 0;
	width: 100%;
}
.one-header-sub-nav__button svg {
	height: 24px;
	margin-right: var(--one-header-space-s);
	transform: scaleX(-1);
	width: 24px;
}

.one-header-sub-nav__list-teaser-wrapper {
	flex-direction: column;
	background-color: var(--one-header-color-white);
	display: flex;
	margin: var(--one-header-space-xl) 0 0 0;
	overflow: visible;
}

.one-header-sub-nav__list-container {
	display: block;
	margin: 0 0 var(--one-header-space-xxxl);
}

.one-header-sub-nav__list {
	list-style: none;
	margin: 0px;
	opacity: 1;
	padding: 0px;
	width: 100%;
}

.one-header-sub-nav__list li {
	margin-bottom: var(--one-header-space-l);
	position: relative;
}
.one-header-sub-nav__list li:last-of-type {
	margin-bottom: 0px;
}
.one-header-sub-nav__item--audi-sport {
	padding-left: 42px;
}
.one-header-sub-nav__item--audi-sport::before {
	background-color: rgb(245, 5, 55);
	content: "";
	display: inline-block;
	height: 24px;
	left: 6px;
	position: absolute;
	transform: skew(-28deg);
	vertical-align: top;
	width: 24px;
}

@media all and (max-width: 1439px) {
	.one-header-nav-item--subnav-open .one-header-sub-nav {
		backface-visibility: hidden;
		background-color: var(--one-header-color-white);
		inset: 0px;
		display: block;
		height: calc(728px - var(--one-header-height));
		overflow-y: auto;
		padding: 0 var(--one-header-side-spacing);
		position: absolute;
		transform: translateX(0px);
		transition: transform 420ms cubic-bezier(0.75, 0.02, 0.5, 1) 0s, height 420ms cubic-bezier(0.75, 0.02, 0.5, 1) 0s;
	}
}

@media all and (min-width: 768px) {
	.one-header-sub-nav__list-teaser-wrapper {
		flex-direction: row;
		-moz-box-pack: justify;
		justify-content: space-between;
	}
	.one-header-sub-nav__list-container {
		margin-right: var(--one-header-space-xl);
	}
}

@media all and (min-width: 1440px) {
	.one-header-sub-nav {
		bottom: initial;
		height: 0px;
		max-height: 0px;
		opacity: 0;
		overflow-x: hidden;
		overflow-y: initial;
		padding: 0 var(--one-header-side-spacing) 0 var(--one-header-flyout-left-spacing);
		top: 72px;
		transform: none;
		transition: max-height 420ms cubic-bezier(0.75, 0.02, 0.5, 1) 0s, opacity 180ms cubic-bezier(0.75, 0.02, 0.5, 1) 0s;
		z-index: -1;
	}
	.one-header-nav-item--subnav-open .one-header-sub-nav {
		height: auto;
		max-height: 660px;
		opacity: 1;
	}
	.one-header-sub-nav__button { display: none; }
	.one-header-sub-nav__list-teaser-wrapper {
		margin: var(--one-header-space-xxl) 0 0 0;
	}
	.one-header-sub-nav__list-container {
		column-count: 2;
		column-gap: var(--one-header-space-xxl);
	}
	.one-header-sub-nav__list {
		width: 320px;
	}
}

@media all and (min-width: 1920px) {
	.one-header-sub-nav__list-container {
		column-count: 3;
	}
}
