.audi-link-l {
	/* color: var(--one-header-color-black); */
	cursor: pointer;
	display: inline-block;
	font-family: AudiTypeExtended, sans-serif;
	font-weight: bold;
	font-size: 1.7em;
	line-height: normal;
	text-decoration: none;
}

/* .audi-link-l:hover,
.audi-link-l:focus {
	color: var(--one-header-color-grey-70);
} */

/* @media all and (min-width: 768px) {
	.audi-link-l {
		font-size: 2.1em;
		line-height: normal;
	}
} */
